const linkOnSuccess = ({ onSuccess }, suffix) => {
  if (onSuccess) {
    const functionId = 'redux' + suffix + 'OnSuccess';
    window[functionId] = onSuccess;
    return { onSuccess: functionId };
  } else {
    return {};
  }
};

export const execOnSuccess = (functionId) => {
  if (
    functionId &&
    window[functionId] &&
    typeof window[functionId] === 'function'
  ) {
    window[functionId]();
    window[functionId] = null;
  }
};

// category modal actions
export const setIsCreateCategoryModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_CATEGORY_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateCategoryModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_CATEGORY_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateCategory') },
  });
};

export const setIsEditCategoryModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_CATEGORY_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditCategoryModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_CATEGORY_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditCategory') },
  });
};

// item modal actions
export const setIsCreateItemModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_ITEM_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateItemModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_ITEM_MODAL_DATA',
    payload: data,
  });
};

export const setIsEditItemModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_ITEM_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditItemModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_ITEM_MODAL_DATA',
    payload: data,
  });
};

// item modal actions
export const setIsCreateAdditionModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_ADDITION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateAdditionModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_ADDITION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateAddition') },
  });
};

export const setIsEditAdditionModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_ADDITION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditAdditionModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_ADDITION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditAddition') },
  });
};

export const setItemInfoModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_ITEM_INFO_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'ItemInfo') },
  });
};

export const setIsItemInfoModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_ITEM_INFO_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsSetFiltersModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_SET_FILTERS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setSelectAdditionsModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_SELECT_ADDITIONS_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'SelectAdditions') },
  });
};

export const setIsSelectAdditionsModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_SELECT_ADDITIONS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setListAdditionsModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_LIST_ADDITIONS_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'ListAdditions') },
  });
};

export const setIsListAdditionsModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_LIST_ADDITIONS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsCartModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CART_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsCheckoutModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CHECKOUT_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsPickupStatusModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_PICKUP_STATUS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsEditMenuModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_MENU_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsTipModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_TIP_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsWhatsappModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_WHATSUP_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setTipModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_TIP_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'Tip') },
  });
};

export const setIsListOrdersModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_LIST_ORDERS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setListOrdersModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_LIST_ORDERS_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'ListOrders') },
  });
};

export const setIsMoreSettingsModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_MORE_SETTINGS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setMoreSettingsModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_MORE_SETTINGS_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'MoreSettings') },
  });
};

export const setIsEditStoreLogoModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_STORE_LOGO_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditStoreLogoModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_STORE_LOGO_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'StoreLogo') },
  });
};

export const setIsOrderInfoModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_ORDER_INFO_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setOrderInfoModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_ORDER_INFO_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'OrderInfo') },
  });
};

export const setIsCreateTableModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_TABLE_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateTableModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_TABLE_MODAL_DATA',
    payload: { ...data },
  });
};

export const setIsEditTableModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_TABLE_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditTableModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_TABLE_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditTable') },
  });
};

export const setIsCreatePrinterModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_PRINTER_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreatePrinterModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_PRINTER_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePrinter') },
  });
};

export const setIsEditPrinterModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_PRINTER_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditPrinterModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_PRINTER_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditPrinter') },
  });
};

export const setCreatePaymentMethodModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_PAYMENT_METHOD_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePaymentMethods') },
  });
};

export const setIsEditPaymentMethodModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_PAYMENT_METHOD_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditPaymentMethodModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_PAYMENT_METHOD_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditPaymentMethods') },
  });
};

export const setListPaymentMethodsModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_LIST_PAYMENT_METHODS_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'ListPaymentMethods') },
  });
};

export const setIsListPaymentMethodsModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_LIST_PAYMENT_METHODS_MODAL_OPEN',
    payload: isOpen,
  });
};
export const setIsCreatePaymentMethodModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_PAYMENT_METHODS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsEditMenuEntryCategoryModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_ITEM_CATEGORY_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditMenuEntryCategoryModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_ITEM_CATEGORY_MODAL_DATA',
    payload: data,
  });
};

export const setListAddressesModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_LIST_ADDRESSES_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'ListAddresses') },
  });
};

export const setIsListAddressesModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_LIST_ADDRESSES_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsUserSettingsModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_USER_SETTINGS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsCreateBillingAddressModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_BILLING_ADDRESS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateBillingAddressModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_BILLING_ADDRESS_MODAL_DATA',
    payload: data,
  });
};

export const setIsEditBillingAddressModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_BILLING_ADDRESS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditBillingAddressModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_BILLING_ADDRESS_MODAL_DATA',
    payload: data,
  });
};

export const setIsCreateDeliveryAddressModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_DELIVERY_ADDRESS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateDeliveryAddressModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_DELIVERY_ADDRESS_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateDeliveryAddress') },
  });
};

export const setIsEditDeliveryAddressModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_DELIVERY_ADDRESS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditDeliveryAddressModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_DELIVERY_ADDRESS_MODAL_DATA',
    payload: data,
  });
};

export const setIsCreatePreferenceModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_PREFERENCE_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsEditPreferenceModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_PREFERENCE_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditPreferenceModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_PREFERENCE_MODAL_DATA',
    payload: data,
  });
};

export const setIsCreateIngredientModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_INGREDIENT_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsEditIngredientModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_INGREDIENT_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditIngredientModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_INGREDIENT_MODAL_DATA',
    payload: data,
  });
};

export const setIsCreateAllergenModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_ALLERGEN_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsEditAllergenModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_ALLERGEN_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditAllergenModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_ALLERGEN_MODAL_DATA',
    payload: data,
  });
};

export const setIsCreateAdditiveModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_ADDITIVE_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsEditAdditiveModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_ADDITIVE_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditAdditiveModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_ADDITIVE_MODAL_DATA',
    payload: data,
  });
};

export const setSelectBillingAddressModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_SELECT_BILLING_ADDRESS_MODAL_DATA',
    payload: data,
  });
};

export const setIsSelectBillingAddressModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_SELECT_BILLING_ADDRESS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setTableCartModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_TABLE_CART_MODAL_DATA',
    payload: data,
  });
};

export const setIsTableCartModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_TABLE_CART_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsCreateHelloTessPosIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_CREATE_HELLO_TESS_POS_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateHelloTessPosIntegrationModalData = (data) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_CREATE_HELLO_TESS_POS_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePosIntegration') },
  });
};

export const setIsEditHelloTessPosIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_EDIT_HELLO_TESS_POS_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditHelloTessPosIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_HELLO_TESS_POS_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditHelloTessPosIntegration') },
  });
};

export const setIsCreateMergePortPosIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_CREATE_MERGE_PORT_POS_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateMergePortPosIntegrationModalData = (data) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_CREATE_MERGE_PORT_POS_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePosIntegration') },
  });
};

export const setIsEditMergePortPosIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_EDIT_MERGE_PORT_POS_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditMergePortPosIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_MERGE_PORT_POS_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditMergePortPosIntegration') },
  });
};

export const setIsEditStripeIntegrationModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_STRIPE_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditStripeIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_STRIPE_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditStripeIntegration') },
  });
};

export const setIsEditMerchantProfileModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_MERCHANT_PROFILE_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditMerchantProfileModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_MERCHANT_PROFILE_MODAL_DATA',
    payload: { ...data },
  });
};

export const setIsCreateSmartSchankIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_CREATE_DIRMEINER_SMART_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};
export const setIsCreateDiscountCodesModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_DISCOUNT_CODES_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateSmartSchankIntegrationModalData = (data) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_CREATE_DIRMEINER_SMART_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePosIntegration') },
  });
};

export const setIsEditSmartSchankIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_EDIT_DIRMEINER_SMART_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateDiscountCodesModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_DISCOUNT_CODES_MODAL_DATA',
    payload: { ...data },
  });
};

export const setIsEditDiscountCodesModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_DISCOUNT_CODES_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditSmartSchankIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_DIRMEINER_SMART_INTEGRATION_MODAL_DATA',
    payload: {
      ...data,
      ...linkOnSuccess(data, 'EditSmartSchankIntegration'),
    },
  });
};

export const setIsCreateGoogleTagManagerIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_CREATE_GOOGLE_TAG_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateGoogleTagManagerIntegrationModalData = (data) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_CREATE_GOOGLE_TAG_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePosIntegration') },
  });
};

export const setIsEditGoogleTagManagerIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_EDIT_GOOGLE_TAG_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditGoogleTagManagerIntegrationModalData = (data) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_EDIT_GOOGLE_TAG_INTEGRATION_MODAL_DATA',
    payload: {
      ...data,
    },
  });
};

export const setIsInfoModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_INFO_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setInfoModalData = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_INFO_MODAL_DATA',
    payload: isOpen,
  });
};

export const setIsCreateInfoModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_INFO_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateInfoModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_INFO_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateInfo') },
  });
};

export const setIsEditInfoModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_INFO_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditInfoModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_INFO_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditInfo') },
  });
};

export const setEditDiscountCodesModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_DISCOUNT_CODES_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditDiscountCode') },
  });
};

export const setIsTableReservationModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_TABLE_RESERVATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIsSignUpNoticeModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_SIGN_UP_NOTICE_MODAL_OPEN',
    payload: isOpen,
  });
};
export const setIsIncomingOrderConfirmationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_INCOMING_ORDER_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setIncomingOrderConfirmationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_IS_INCOMING_ORDER_MODAL_DATA',
    payload: { ...data },
  });
};

export const setIsCreateHubrisePosIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_CREATE_HUBRISE_POS_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateHubrisePosIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_HUBRISE_POS_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePosIntegration') },
  });
};

export const setIsEditHubrisePosIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_EDIT_HUBRISE_POS_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditHubrisePosIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_HUBRISE_POS_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditHubrisePosIntegration') },
  });
};

export const setIsCreateOrderConditionsModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_ORDER_CONDITIONS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateOrderConditionsModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_ORDER_CONDITIONS_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateOrderConditions') },
  });
};

export const setIsEditOrderConditionsModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_ORDER_CONDITIONS_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditOrderConditionsModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_ORDER_CONDITIONS_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditOrderConditions') },
  });
};

export const setIsCreateItemAdditionGroupModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_ITEM_ADDITION_GROUP_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateItemAdditionGroupModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_ITEM_ADDITION_GROUP_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateItemAdditionGroup') },
  });
};

export const setIsEditItemAdditionGroupModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_ITEM_ADDITION_GROUP_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditItemAdditionGroupModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_ITEM_ADDITION_GROUP_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditItemAdditionGroup') },
  });
};

export const setIsEditCategoryBulkModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_CATEGORY_BULK_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditCategoryBulkModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_CATEGORY_BULK_MODAL_DATA',
    payload: data,
  });
};

export const setIsCreateKioskModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_KIOSK_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateKioskModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_KIOSK_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateKiosk') },
  });
};

export const setIsEditKioskModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_KIOSK_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditKioskModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_KIOSK_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditKiosk') },
  });
};

export const setIsCreateTerminalModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_TERMINAL_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateTerminalModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_TERMINAL_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateTerminal') },
  });
};

export const setIsEditTerminalModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_TERMINAL_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditTerminalModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_TERMINAL_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditTerminal') },
  });
};

export const setIsCreatePickupStationModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_PICKUP_STATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreatePickupStationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_PICKUP_STATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePickupStation') },
  });
};

export const setIsEditPickupStationModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_PICKUP_STATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditPickupStationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_PICKUP_STATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditPickupStation') },
  });
};

export const setIsCreateMatrixPosIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_CREATE_MATRIX_POS_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateMatrixPosIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_MATRIX_POS_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePosIntegration') },
  });
};

export const setIsEditMatrixPosIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_EDIT_MATRIX_POS_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditMatrixPosIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_MATRIX_POS_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditMatrixPosIntegration') },
  });
};

export const setIsCreateConnectorModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_CONNECTOR_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateConnectorModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_CONNECTOR_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateConnector') },
  });
};

export const setIsEditConnectorModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_CONNECTOR_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditConnectorModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_CONNECTOR_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditConnector') },
  });
};

export const setIsCreateScannerIntegrationModalOpen = (isOpen) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_IS_CREATE_SCANNER_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateScannerIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_SCANNER_INTEGRATION_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreatePosIntegration') },
  });
};

export const setIsEditScannerIntegrationModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_SCANNER_INTEGRATION_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditScannerIntegrationModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_SCANNER_INTEGRATION_MODAL_DATA',
    payload: {
      ...data,
      ...linkOnSuccess(data, 'EditScannerIntegration'),
    },
  });
};

export const setIsCreateAreaModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_CREATE_AREA_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setCreateAreaModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CREATE_AREA_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'CreateArea') },
  });
};

export const setIsEditAreaModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_IS_EDIT_AREA_MODAL_OPEN',
    payload: isOpen,
  });
};

export const setEditAreaModalData = (data) => (dispatch) => {
  dispatch({
    type: 'SET_EDIT_AREA_MODAL_DATA',
    payload: { ...data, ...linkOnSuccess(data, 'EditArea') },
  });
};

import React from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { capitalize } from '../util/util.service';
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_DELIVERING,
  ORDER_STATUS_PENDING,
  ORDER_STATUS_PREPARED,
  ORDER_STATUS_PREPARING,
  ORDER_STATUS_PROCESSING,
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_PICKUP,
} from '../constants';

const message = (id, values) => <FormattedMessage id={id} values={values} />;

const getClassName = (type) => {
  switch (type) {
    case 'success':
    case 'primary':
      return 'bg-primary';
    case 'secondary':
      return 'bg-secondary';
    case 'info':
      return 'bg-info';
    case 'warn':
    case 'warning':
      return 'bg-warning';
    case 'error':
    case 'danger':
      return 'bg-danger';
    default:
      return 'bg-primary';
  }
};

export const notification = (
  type = 'primary',
  id,
  autoClose = true,
  customId = +new Date(),
) => {
  toast[type](message(id), {
    position: toast.POSITION.TOP_RIGHT,
    className: getClassName(type),
    autoClose,
    toastId: customId,
  });
};

export const unClosableNotification = (
  type = 'primary',
  id,
  autoClose = false,
  toastId = +new Date(),
) => {
  toast[type](message(id), {
    position: toast.POSITION.TOP_RIGHT,
    className: getClassName(type),
    autoClose,
    toastId,
  });
};

export const customMessageNotification = (
  type = 'primary',
  message,
  autoClose = true,
  toastId = +new Date(),
) => {
  toast[type](message, {
    position: toast.POSITION.TOP_RIGHT,
    className: getClassName(type),
    autoClose,
    toastId,
  });
};

export const handleOrderUpdateNotification = (order, allOrders) => {
  const existingOrder = allOrders?.find(({ id }) => id === order.id);

  // only show notification if one of the following statuses AND if status really changed
  // there might be Order updates, that does not affect the status
  if (
    [
      ORDER_STATUS_PENDING,
      ORDER_STATUS_PROCESSING,
      ORDER_STATUS_PREPARING,
      ORDER_STATUS_PREPARED,
      ORDER_STATUS_DELIVERING,
      ORDER_STATUS_CANCELLED,
    ].includes(order.status) &&
    existingOrder?.status !== order.status
  ) {
    const type = order.status === ORDER_STATUS_CANCELLED ? 'error' : 'success';
    const messageId =
      'orderStatus' + capitalize(order.status) + 'Notification@' + order.type;
    const messageValues = {
      number: order?.number,
      storeName: order.merchantProfile.store.name,
    };

    toast[type](message(messageId, messageValues), {
      position: toast.POSITION.TOP_RIGHT,
      className: getClassName(type),
      autoClose: true,
      toastId: `${order.id}-${+new Date()}}}`,
    });
  }
};

export const notificationFormattedMessage = (
  type = 'primary',
  id,
  values,
  autoClose = true,
  customId = +new Date(),
) => {
  toast[type](message(id, values), {
    position: toast.POSITION.TOP_RIGHT,
    className: getClassName(type),
    autoClose,
    toastId: customId,
  });
};

export const handleExpectedTimeUpdateNotification = (order, allOrders) => {
  const existingOrder = allOrders?.find(({ id }) => id === order.id);
  // Check if confirmedPickupTime or confirmedDeliveryTime has been updated
  if (
    existingOrder &&
    ((order?.type === ORDER_TYPE_PICKUP &&
      order?.pickupConfirmedAt !== existingOrder?.pickupConfirmedAt) ||
      (order?.type === ORDER_TYPE_DELIVERY &&
        order?.deliveryConfirmedAt !== existingOrder?.deliveryConfirmedAt))
  ) {
    const type = 'success';
    const messageId =
      order?.type === ORDER_TYPE_PICKUP
        ? 'confirmedPickupTimeUpdatedNotification'
        : 'confirmedDeliveryTimeUpdatedNotification';
    const messageValues = {
      number: order?.number,
      storeName: order.merchantProfile.store.name,
    };

    toast[type](message(messageId, messageValues), {
      position: toast.POSITION.TOP_RIGHT,
      className: getClassName(type),
      autoClose: true,
    });
  }
};

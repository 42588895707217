import React, { useState } from 'react';
import VerticalLayout from '../layouts/vertical-layout.component';
import FullLayout from '../layouts/fullpage-layout.component';
import themeConfig from '../configs/theme.config';
import PropTypes from 'prop-types';

const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
};

const ContextLayout = React.createContext({});

const Layout = ({ children }) => {
  const [direction, setDirection] = useState(themeConfig.direction);
  const [activeLayout, setActiveLayout] = useState(themeConfig.layout);

  const updateDir = (dir) => {
    setDirection(dir);
    document.getElementsByTagName('html')[0].setAttribute('dir', dir || 'ltr');
  };

  return (
    <ContextLayout.Provider
      value={{
        activeLayout: activeLayout,
        layouts: layouts,
        direction: direction,
        switchLayout: (layout) => setActiveLayout(layout),
        switchDir: (dir) => updateDir(dir),
      }}
    >
      {children}
    </ContextLayout.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export { Layout, ContextLayout };

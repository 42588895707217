const APP_FALLBACK_LOCALE = 'en';
const APP_SUPPORTED_LOCALES = ['de', 'en', 'it', 'fr', 'es', 'hu'];

const isCloudflarePages = !!process.env.REACT_APP_CF_PAGES_BRANCH;

// prepare branch, sanitizedBranch is used to build the apiHostname
const branch =
  process.env.REACT_APP_CF_PAGES_BRANCH ||
  process.env.REACT_APP_BRANCH ||
  'unknown';
const sanitizedBranch = branch.replace(/[^a-z0-9]/gi, '-');

// determine env by the subdomain (if REACT_APP_ENV not set) with fallback to dev
const subdomain = window.location.hostname
  .match(/^(.+?)\.servier\.bar$/)?.[1]
  .toLowerCase();
const env =
  process.env.REACT_APP_ENV ||
  (subdomain === 'app'
    ? 'prod'
    : subdomain === 'demo' || subdomain === 'staging'
    ? subdomain
    : 'dev');

// build apiHostname depending on env
const apiHostname =
  env === 'prod'
    ? 'api.servier.bar'
    : ['dev', 'staging', 'demo'].includes(branch)
    ? `${branch}.api.servier.bar`
    : `${sanitizedBranch}.dev.api.servier.bar`; // all other branches e.g. feat-cool-thing.dev.api.servier.bar

// build the config
const config = {
  env,
  name: process.env.REACT_APP_NAME || 'sb-fend',
  version: process.env.REACT_APP_VERSION || 'unknown',
  branch,
  commitSha: process.env.REACT_APP_COMMIT_SHA,
  stripePublicKey: process.env.REACT_APP_STRIPE_API_PUBLIC_KEY,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || `https://${apiHostname}`,
  wsBaseUrl: process.env.REACT_APP_WS_BASE_URL || `wss://${apiHostname}`,
  shortUrl: isCloudflarePages
    ? env === 'prod'
      ? `https://menu.sb`
      : ['dev', 'staging', 'demo'].includes(branch)
      ? `https://${branch}.menu.sb`
      : undefined
    : undefined, // *menu.sb URLs are only available on Cloudflare
  auth0: {
    domain: process.env[`REACT_APP_${env.toUpperCase()}_AUTH0_DOMAIN`],
    clientId: process.env[`REACT_APP_${env.toUpperCase()}_AUTH0_CLIENT_ID`],
    audience: process.env[`REACT_APP_${env.toUpperCase()}_AUTH0_AUDIENCE`],
  },
  cfImages: {
    accountHash: process.env.REACT_APP_CF_IMAGES_ACCOUNT_HASH,
    baseUrl: isCloudflarePages
      ? `${window.location.origin}/cdn-cgi/imagedelivery/${process.env.REACT_APP_CF_IMAGES_ACCOUNT_HASH}` // the /cdn-cgi/... URL works only on Cloudflare
      : `https://imagedelivery.net/${process.env.REACT_APP_CF_IMAGES_ACCOUNT_HASH}`,
  },
  sentry: { dsn: process.env.REACT_APP_SENTRY_DSN },
  google: { apiKey: process.env.REACT_APP_GOOGLE_API_KEY },
};

export {
  APP_FALLBACK_LOCALE,
  APP_SUPPORTED_LOCALES,
  isCloudflarePages,
  config,
};

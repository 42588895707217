import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonLoader = ({ inputStyle, style, ...props }) => {
  return (
    <Skeleton
      style={{
        ...(inputStyle && {
          height: 'calc(1.25 * 1em + 1.4rem + 1px)',
          marginBottom: '1.25rem',
        }),
        ...style,
      }}
      {...props}
    />
  );
};

export default SkeletonLoader;

import React from 'react';
import { Link } from 'react-router-dom';
import { Disc, X, Circle } from 'react-feather';
import classnames from 'classnames';
import { connect } from 'react-redux';
import LazyImage from '../components/@servierbar/lazyload/lazy-image/lazy-image.component';
import PropTypes from 'prop-types';
import { setIsSidebarOpen } from '../redux/customizer/customizer.actions';

const SidebarHeader = ({
  isSidebarOpen,
  menuTheme,
  store,

  setIsSidebarOpen,
}) => {
  const storeLogo = store?.logo;

  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li
          className="nav-item mr-auto"
          onClick={(e) => {
            e.stopPropagation();
            setIsSidebarOpen(false);
          }}
        >
          <Link to={`/store/${store?.slug}`} className="navbar-brand">
            <LazyImage
              image={storeLogo}
              src={storeLogo === null && '/assets/logos/sb-logo-green.svg'} // compare explicitly to null => coverImage was not set
              variant="storeLogo"
              className="store-logo"
              placeholderContainerClassNames="zindex-4"
              placeholderClassNames="store-logo"
            />
          </Link>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle">
            {isSidebarOpen ? (
              <Disc
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className={classnames(
                  'toggle-icon icon-x d-none d-xl-block font-medium-4',
                  {
                    'text-primary': menuTheme === 'primary',
                    'text-success': menuTheme === 'success',
                    'text-danger': menuTheme === 'danger',
                    'text-info': menuTheme === 'info',
                    'text-warning': menuTheme === 'warning',
                    'text-dark': menuTheme === 'dark',
                  },
                )}
                size={20}
                data-tour="toggle-icon"
              />
            ) : (
              <Circle
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className={classnames(
                  'toggle-icon icon-x d-none d-xl-block font-medium-4',
                  {
                    'text-primary': menuTheme === 'primary',
                    'text-success': menuTheme === 'success',
                    'text-danger': menuTheme === 'danger',
                    'text-info': menuTheme === 'info',
                    'text-warning': menuTheme === 'warning',
                    'text-dark': menuTheme === 'dark',
                  },
                )}
                size={20}
              />
            )}
            <X
              onClick={() => setIsSidebarOpen(false)}
              className={classnames(
                'toggle-icon icon-x d-block d-xl-none font-medium-4',
                {
                  'text-primary': menuTheme === 'primary',
                  'text-success': menuTheme === 'success',
                  'text-danger': menuTheme === 'danger',
                  'text-info': menuTheme === 'info',
                  'text-warning': menuTheme === 'warning',
                  'text-dark': menuTheme === 'dark',
                },
              )}
              size={20}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

SidebarHeader.propTypes = {
  isSidebarOpen: PropTypes.bool,
  menuTheme: PropTypes.string,
  store: PropTypes.object,
  setIsSidebarOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isSidebarOpen: state.customizer.isSidebarOpen,
  menuTheme: state.customizer.menuTheme,
  store: state.store.store,
});

export default connect(mapStateToProps, {
  setIsSidebarOpen,
})(SidebarHeader);

import React, { useState } from 'react';
import classnames from 'classnames';
import * as Icon from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, NavItem, NavLink } from 'reactstrap';
import Autocomplete from '../components/@vuexy/autoComplete/AutoCompleteComponent';
import {
  setIsListOrdersModalOpen,
  setIsMoreSettingsModalOpen,
  setIsPickupStatusModalOpen,
  setIsSetFiltersModalOpen,
} from '../redux/modal/modal.actions';
import PropTypes from 'prop-types';
import { setShowAppOverlay } from '../redux/customizer/customizer.actions';
import TagManager from 'react-gtm-module/dist/TagManager';
import { isAdminMode } from '../utility/util/util.service';

const NavbarUser = ({
  me,
  store,
  menuEntriesData,
  pickupOrder,
  isSearchOpen,
  menuEntries,
  kiosk,

  setIsListOrdersModalOpen,
  isSetFiltersModalOpen,
  setIsSetFiltersModalOpen,
  foodAllergensFilter,
  foodPreferencesFilter,
  foodIngredientsFilter,
  auth0,
  /*setIsPickupStatusModalOpen,*/
  setIsMoreSettingsModalOpen,
  setShowAppOverlay,
  setIsSearchOpen,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const areAnyFiltersSet =
    foodAllergensFilter?.length > 0 ||
    foodPreferencesFilter?.length > 0 ||
    foodIngredientsFilter?.length > 0;

  const filterUniqueObjects = (array, property) => {
    const seen = new Set();
    return array.filter((obj) => {
      const value = obj[property];
      return seen.has(value) ? false : seen.add(value);
    });
  };

  const handleSearch = (e) => {
    const searchFor = e.target.value.toLowerCase();
    const suggestions =
      menuEntriesData?.filter((data) =>
        data.searchString.toLowerCase().includes(searchFor),
      ) || [];

    const suggestionMap = new Map();

    suggestions.forEach((suggestion) => {
      const matchingMenuEntries = menuEntries?.filter(
        (menuEntry) =>
          Array.isArray(menuEntry?.itemEntry?.data) &&
          menuEntry.itemEntry.data.some(
            (itemData) => itemData?.id === suggestion?.id,
          ),
      );

      const categories =
        matchingMenuEntries?.reduce((acc, menuEntry) => {
          return acc.concat(menuEntry?.itemEntry?.categories || []);
        }, []) || [];

      const uniqueCategories = [...new Set(categories)];

      if (suggestionMap.has(suggestion.id)) {
        const existingSuggestion = suggestionMap.get(suggestion.id);
        existingSuggestion.categories = [...new Set([...uniqueCategories])];
      } else {
        suggestionMap.set(suggestion.id, {
          ...suggestion,
          categories: uniqueCategories,
        });
      }
    });

    const mergedSuggestions = Array.from(suggestionMap.values());
    setSuggestions(
      mergedSuggestions.map((suggestion) => {
        return {
          ...suggestion,
          categories: filterUniqueObjects(suggestion?.categories, 'id'),
        };
      }),
    );

    clearTimeout(window.searchTimeout);
    window.searchTimeout = setTimeout(() => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'InitSearch',
          customerProfileId: me?.requester?.customerProfile?.id,
          searchText: searchFor,
        },
      });
    }, 1500);
  };

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right disable-dbl-tap-zoom rounded-bottom">
      {store && menuEntriesData && (
        <>
          {!isAdminMode(me, store?.slug) && !kiosk && (
            <NavItem
              className="p-0 d-flex align-items-center"
              onClick={() => setIsListOrdersModalOpen(true)}
            >
              <NavLink className={classnames('nav-link-search', 'mr-1')}>
                <Icon.ShoppingCart size={21} data-testid="ordersShortcut" />
              </NavLink>
            </NavItem>
          )}
          <NavItem
            className="nav-search p-0 d-flex align-items-center"
            onClick={() => setIsSearchOpen(!isSearchOpen)}
          >
            <NavLink className={classnames('nav-link-search', 'mr-1')}>
              <Icon.Search size={21} data-tour="search" />
            </NavLink>
            <div
              className={classnames('search-input', {
                open: isSearchOpen,
                'd-none': !isSearchOpen,
              })}
            >
              <div className="search-input-icon">
                <Icon.Search size={17} className="primary" />
              </div>
              <FormattedMessage id="searchPlaceholder">
                {(placeholder) => (
                  <Autocomplete
                    className="form-control"
                    suggestions={suggestions}
                    filterKey="title"
                    filterHeaderKey="groupTitle"
                    grouped={true}
                    placeholder={placeholder}
                    autoFocus={true}
                    clearInput={isSearchOpen}
                    externalClick={() => {
                      setShowAppOverlay(false);
                      setIsSearchOpen(false);
                    }}
                    onChange={handleSearch}
                    onKeyDown={(e) => {
                      if (e.keyCode === 27 || e.keyCode === 13) {
                        setShowAppOverlay(false);
                        setIsSearchOpen(false);
                      }
                    }}
                    onSuggestionsShown={(userInput) => {
                      if (isSearchOpen) {
                        setShowAppOverlay(!!userInput);
                      }
                    }}
                    onSuggestionItemClick={() => setShowAppOverlay(false)}
                  />
                )}
              </FormattedMessage>
              <div className="search-input-close">
                <Icon.X
                  size={24}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAppOverlay(false);
                    setIsSearchOpen(false);
                  }}
                />
              </div>
            </div>
          </NavItem>

          <NavItem
            className="p-0 d-flex align-items-center"
            onClick={() => setIsSetFiltersModalOpen(true)}
          >
            <NavLink
              className={classnames('nav-link-search', 'mr-1', {
                'nav-link-filter': areAnyFiltersSet,
              })}
            >
              <Icon.Sliders
                size={21}
                id="filter"
                data-tour="filter"
                className={classnames({ primary: areAnyFiltersSet })}
              />
            </NavLink>
          </NavItem>
        </>
      )}

      <NavItem
        className="p-0 d-flex align-items-center"
        onClick={() => setIsMoreSettingsModalOpen(true)}
      >
        <NavLink className={classnames('nav-link-search', 'mr-1')}>
          <Icon.MoreVertical
            data-testid="moreSettingsButton"
            size={21}
            data-tour="more-settings"
          />
        </NavLink>
      </NavItem>
    </ul>
  );
};

const mapStateToProps = (state) => ({
  menuEntriesData: state.store.menuEntriesData,
  isSetFiltersModalOpen: state.modals.isSetFiltersModalOpen,
  foodAllergensFilter: state.me.foodAllergensFilter,
  foodPreferencesFilter: state.me.foodPreferencesFilter,
  foodIngredientsFilter: state.me.foodIngredientsFilter,
  pickupOrder: state.me.pickupOrder,
  menuEntries: state.store.menuEntries,
  kiosk: state.store.kiosk,
});

export default connect(mapStateToProps, {
  setIsSetFiltersModalOpen,
  /*setIsPickupStatusModalOpen,*/
  setIsListOrdersModalOpen,
  setIsMoreSettingsModalOpen,
  setShowAppOverlay,
})(NavbarUser);

import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronsRight } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getLocalizedName } from '../utility/util/util.service';
import { setIsSidebarOpen } from '../redux/customizer/customizer.actions';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isAdminMode as _isAdminMode } from '../utility/util/util.service';

const SideMenuContent = ({
  store,
  menuCategoryTree,
  table,
  me,

  setIsSidebarOpen,
}) => {
  const intl = useIntl();
  const currentSearchParams = new URLSearchParams(window.location.search);
  const isAdminMode = _isAdminMode(me, store?.slug);

  const filteredMenuCategoryTree =
    table && table?.area?.categories && !isAdminMode
      ? menuCategoryTree?.filter((category) =>
          table.area.categories?.map(({ id }) => id).includes(category?.id),
        )
      : menuCategoryTree;

  return (
    filteredMenuCategoryTree?.map((category, index) =>
      [
        <li
          key={index}
          className="nav-item"
          onClick={(e) => {
            e.stopPropagation();
            setIsSidebarOpen(false);
          }}
        >
          <Link
            to={`/store/${store.slug}/${
              category?.id
            }?${currentSearchParams?.toString()}`}
          >
            <div className="menu-text">
              <span className="primary menu-item menu-title">
                {getLocalizedName(category, intl.locale)}
              </span>
            </div>
          </Link>
        </li>,
        ...(category.children?.map((categoryChild, indexChild) => (
          <li
            key={index + '-' + indexChild}
            className="nav-item"
            onClick={(e) => {
              e.stopPropagation();
              setIsSidebarOpen(false);
            }}
          >
            <Link to={`/store/${store.slug}/${categoryChild.id}`}>
              <div className="menu-text">
                <ChevronsRight className="" size={20} />
                <span className="menu-item menu-title">
                  {getLocalizedName(categoryChild, intl.locale)}
                </span>
              </div>
            </Link>
          </li>
        )) || []),
      ].flat(),
    ) || null
  );
};

SideMenuContent.propTypes = {
  store: PropTypes.object,
  menuCategoryTree: PropTypes.array,
  setIsSidebarOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  store: state.store.store,
  menuCategoryTree: state.store.menuCategoryTree,
  table: state.store.table,
  me: state.me.me,
});

export default connect(mapStateToProps, {
  setIsSidebarOpen,
})(withRouter(SideMenuContent));

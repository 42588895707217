import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Sidebar from '../menu/sidebar.component';
import Navbar from '../navbar/navbar.component';
import Footer from '../footer/footer.component';
import { connect } from 'react-redux';
import {
  setIsSidebarOpen,
  setShowAppOverlay,
} from '../redux/customizer/customizer.actions';
import PropTypes from 'prop-types';

const menuThemeArr = [
  'primary',
  'success',
  'danger',
  'info',
  'warning',
  'dark',
];

const VerticalLayout = ({
  theme,
  menuTheme,
  isSidebarOpen,
  navbarType,
  footerType,
  showAppOverlay,
  children,

  setIsSidebarOpen,
  setShowAppOverlay,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.remove('semi-dark-layout');
      document.body.classList.add('dark-layout');
    } else if (theme === 'semi-dark') {
      document.body.classList.remove('dark-layout');
      document.body.classList.add('semi-dark-layout');
    } else if (theme !== 'dark' && theme !== 'semi-dark') {
      document.body.classList.remove('dark-layout', 'semi-dark-layout');
    }
  }, [theme]);

  return (
    <div
      className={classnames(`wrapper vertical-layout theme-${menuTheme}`, {
        'menu-collapsed': !isSidebarOpen && width >= 1200,
        'fixed-footer': footerType === 'sticky',
        'navbar-static': navbarType === 'static',
        'navbar-sticky': navbarType === 'sticky',
        'navbar-floating': navbarType === 'floating',
        'navbar-hidden': navbarType === 'hidden',
        'theme-primary': !menuThemeArr.includes(menuTheme),
      })}
    >
      <Sidebar width={width} />

      <div
        className={classnames('app-content content', {
          'show-overlay': showAppOverlay,
        })}
        /*onClick={() => setIsSidebarOpen(false)}*/
      >
        <Navbar />
        <div className="content-wrapper boxed">{children}</div>
      </div>

      <Footer />

      {/*{!disableCustomizer && (
          <Customizer {...customizerProps} />
        )}*/}

      <div
        className="sidenav-overlay"
        onClick={() => setIsSidebarOpen(false)}
      />
    </div>
  );
};

VerticalLayout.propTypes = {
  theme: PropTypes.string,
  menuTheme: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  showAppOverlay: PropTypes.bool,
  navbarType: PropTypes.string,
  footerType: PropTypes.string,
  children: PropTypes.any,
  setIsSidebarOpen: PropTypes.func,
  setShowAppOverlay: PropTypes.func,
};

const mapStateToProps = (state) => ({
  theme: state.customizer.theme,
  isSidebarOpen: state.customizer.isSidebarOpen,
  showAppOverlay: state.customizer.showAppOverlay,
  menuTheme: state.customizer.menuTheme,
  navbarType: state.customizer.navbarType,
  footerType: state.customizer.footerType,
});

export default connect(mapStateToProps, {
  setIsSidebarOpen,
  setShowAppOverlay,
})(VerticalLayout);

import {
  ORDER_STATUS_DELIVERING,
  ORDER_STATUS_PREPARED,
  ORDER_STATUS_PREPARING,
} from '../../utility/constants';

const initialState = {
  me: null,
  foodAllergensFilter: null,
  foodPreferencesFilter: null,
  foodIngredientsFilter: null,
  pickupOrder: null,
  orders: null,
  paymentMethods: null,
  deliveryAddresses: null,
  billingAddresses: null,
  createPaymentMethodClientSecret: null,
};

export default function me(state = initialState, action) {
  switch (action.type) {
    case 'SET_ME':
      return {
        ...state,
        me: action.payload,
      };
    case 'SET_FOOD_ALLERGENS_FILTER':
      return {
        ...state,
        foodAllergensFilter: action.payload,
      };
    case 'SET_FOOD_PREFERENCES_FILTER':
      return {
        ...state,
        foodPreferencesFilter: action.payload,
      };
    case 'SET_FOOD_INGREDIENTS_FILTER':
      return {
        ...state,
        foodIngredientsFilter: action.payload,
      };
    case 'SET_CREATE_PAYMENT_METHOD_CLIENT_SECRET':
      return {
        ...state,
        createPaymentMethodClientSecret: action.payload,
      };
    case 'SET_PICKUP_ORDER':
      return {
        ...state,
        pickupOrder: action.payload,
      };
    case 'SET_ORDERS':
      const orders = action.payload.sort(sortByCreatedAt);

      return {
        ...state,
        orders: orders,
      };
    case 'SET_PAYMENT_METHODS':
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case 'SET_DELIVERY_ADDRESSES':
      return {
        ...state,
        deliveryAddresses: action.payload,
      };
    case 'SET_BILLING_ADDRESSES':
      return {
        ...state,
        billingAddresses: action.payload,
      };
    case 'SET_ME_SPECIFIC_ORDER':
      const existingOrders = state?.orders || [];
      const existingOrderIndex = existingOrders.findIndex(
        (order) => order.id === action.payload.id,
      );

      if (existingOrderIndex !== -1) {
        // Replace the existing order with the action payload
        const updatedOrders = [...existingOrders];
        updatedOrders[existingOrderIndex] = action.payload;

        return {
          ...state,
          orders: updatedOrders,
        };
      } else {
        // Add the order at the beginning of the orders array
        return {
          ...state,
          orders: [action.payload, ...existingOrders],
        };
      }

    case 'UPDATE_SINGLE_ORDER':
      const originalOrder =
        state.orders?.find(({ id }) => id === action.payload.id) || null;

      // you can pass a partial Order, but id is mandatory
      if (originalOrder) {
        const updatedOrders = [
          ...state.orders?.filter(({ id }) => id !== action.payload.id),
          { ...originalOrder, ...action.payload },
        ].sort(sortByCreatedAt);

        return {
          ...state,
          orders: updatedOrders,
        };
      } else {
        console.log('Could not update: original Order not found');
      }

    default:
      return state;
  }
}

const sortByCreatedAt = (a, b) => new Date(b.createdAt) - new Date(a.createdAt);

const filterPendingOrders = (orders) =>
  orders?.filter(({ status }) =>
    [
      ORDER_STATUS_PREPARED,
      ORDER_STATUS_DELIVERING,
      ORDER_STATUS_PREPARING,
    ].includes(status),
  ) || [];

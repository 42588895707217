import {
  getOrderStats,
  getFoodAllergens,
  getFoodIngredients,
  getFoodItemAdditions,
  getFoodPreferences,
  getItemCategories,
  getMenuEntries,
  getStore,
  getStoreTables,
  getStorePrinters,
  getOrders,
  getServiceTimes,
  getCurrentServiceTime,
  getNextStartServiceTime,
  getPosIntegrations,
  getPosIntegrationItemDataMappings,
  getPosIntegrationItemAdditionMappings,
  getPosIntegrationTableMappings,
  getStores,
  getAllStoresStats,
  getFoodAdditives,
  getStoreDiscountCodes,
  getAdditionGroups,
  getPaymentTerminals,
  getStorePickupStations,
  getKiosks,
  getConnectors,
  getAreas,
} from '../../utility/sb-api/sb-api.service';
import {
  getOrderItemHash,
  getFirstDateOfMonth,
  getItemAdditionQtySum,
  getItemAdditionsTotalSum,
  getItemDataQtySum,
  getItemDataTotalSum,
  getLastDateOfMonth,
  getLocaleHierarchy,
  getOrderItemTotal,
  hasSatisfiedOrderCondition,
} from '../../utility/util/util.service';
import {
  ORDER_STATUS_PAID,
  ORDER_STATUS_PENDING,
} from '../../utility/constants';
import { notification } from '../../utility/notification/notification.service';

// fetch all relevant data combined on app entry
export const fetchOnAppEntry = () => (dispatch) => {
  dispatch(fetchFoodAllergens());
  dispatch(fetchFoodAdditives());
  dispatch(fetchFoodPreferences());
  dispatch(fetchFoodIngredients());
};

export const fetchOnAdminAppEntry = () => (dispatch) => {
  dispatch(fetchStores());
  dispatch(fetchAllStoresStats());
};

// fetch all relevant data combined on store entry / store change
export const fetchOnStoreEntry = (store, isAdminMode = false) => (dispatch) => {
  dispatch(fetchMenuEntries(store.menu.id, isAdminMode));
  dispatch(fetchItemCategories(store.menu.id, isAdminMode));
  if (isAdminMode) {
    dispatch(fetchOrderStats());
    dispatch(fetchFoodItemAdditions());
    dispatch(fetchTables());
    dispatch(fetchDiscountCodes());
    dispatch(fetchPrinters());
    dispatch(fetchPickupStations());
    dispatch(fetchKiosks());
    dispatch(fetchPaymentTerminals());
    dispatch(fetchStoreOrders());
    dispatch(
      fetchPosIntegrationTableMappings(
        store?.merchantProfile?.posIntegration?.id,
      ),
    );
    /*dispatch(fetchPosIntegrations());*/
    dispatch(fetchAdditionGroups());
    dispatch(fetchConnectors());
    dispatch(fetchAreas());
  }
};

export const setStore = (store, flush = false) => (dispatch) => {
  dispatch({
    type: flush ? 'SET_STORE_AND_FLUSH' : 'SET_STORE',
    payload: store,
  });
};

export const fetchStore = (
  slug,
  isAdminMode,
  flush = false,
  onSuccessCallback = () => undefined,
) => (dispatch) => {
  getStore(slug, isAdminMode)
    .then((store) => dispatch(setStore(store, flush)))
    .then(() => onSuccessCallback());
};

export const fetchMenuEntries = (
  menuId,
  isAdminMode,
  onSuccessCallback = () => undefined,
) => (dispatch) => {
  getMenuEntries(menuId, isAdminMode)
    .then((menuEntries) =>
      dispatch({
        type: 'SET_MENU_ENTRIES',
        payload: menuEntries,
      }),
    )
    .then(() => onSuccessCallback());
};

export const fetchItemCategories = (
  menuId,
  isAdminMode,
  onSuccessCallback = () => undefined,
) => (dispatch) => {
  getItemCategories(menuId, isAdminMode)
    .then((itemCategories) =>
      dispatch({
        type: 'SET_ITEM_CATEGORIES',
        payload: itemCategories,
      }),
    )
    .then(() => onSuccessCallback());
};

export const fetchFoodAllergens = (onSuccessCallback = () => undefined) => (
  dispatch,
) => {
  getFoodAllergens()
    .then((foodAllergens) =>
      dispatch({
        type: 'SET_FOOD_ALLERGENS',
        payload: foodAllergens.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        ),
      }),
    )
    .then(() => onSuccessCallback());
};

export const fetchFoodAdditives = (onSuccessCallback = () => undefined) => (
  dispatch,
) => {
  getFoodAdditives()
    .then((foodAdditives) =>
      dispatch({
        type: 'SET_FOOD_ADDITIVES',
        payload: foodAdditives.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        ),
      }),
    )
    .then(() => onSuccessCallback());
};

export const fetchFoodPreferences = (onSuccessCallback = () => undefined) => (
  dispatch,
) => {
  getFoodPreferences()
    .then((foodPreferences) =>
      dispatch({
        type: 'SET_FOOD_PREFERENCES',
        payload: foodPreferences.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        ),
      }),
    )
    .then(() => onSuccessCallback());
};

export const fetchFoodIngredients = (onSuccessCallback = () => undefined) => (
  dispatch,
) => {
  getFoodIngredients()
    .then((foodIngredients) =>
      dispatch({
        type: 'SET_FOOD_INGREDIENTS',
        payload: foodIngredients.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        ),
      }),
    )
    .then(() => onSuccessCallback());
};

// admin only
export const fetchFoodItemAdditions = (onSuccessCallback = () => undefined) => (
  dispatch,
) => {
  getFoodItemAdditions()
    .then((foodItemAdditions) =>
      dispatch({
        type: 'SET_FOOD_ITEM_ADDITIONS',
        payload: foodItemAdditions.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        ),
      }),
    )
    .then(() => onSuccessCallback());
};

export const setCart = (cart) => (dispatch) => {
  dispatch({
    type: 'SET_CART',
    payload: cart,
  });
};

export const updateCartItem = (
  cart,
  targetHash,
  updateFunc = (item) => item,
  checkout,
) => (dispatch) => {
  const existingOrderItem = cart?.find(({ hash }) => targetHash === hash);

  if (!existingOrderItem) {
    throw new Error(`Could not find OrderItem with hash "${targetHash}"`);
  }

  const updatedOrderItem = {
    ...existingOrderItem,
    ...updateFunc(existingOrderItem),
  };
  const itemData = existingOrderItem.itemData;
  const updatedCart = [
    ...cart.filter(({ hash }) => targetHash !== hash),
    updatedOrderItem,
  ]
    .filter(({ qty }) => qty > 0) // remove items with qty = 0;
    .sort((a, b) => a.hash.localeCompare(b.hash)); // sort by hash

  // dispatch the updated cart if OrderConditions are satisfied. Otherwise, show an Error Notification.
  if (
    updatedOrderItem?.qty === 0 || //fixes a bug which caused that you could not remove an orderItem from cart
    (hasSatisfiedOrderCondition(
      itemData.orderConditions,
      checkout?.orderType,
      getItemDataTotalSum(updatedCart, itemData, checkout?.orderType),
      getItemDataQtySum(updatedCart, itemData),
    ) &&
      existingOrderItem?.additions.every((orderItemAddition) => {
        const addition = orderItemAddition.additionGroupEntry?.addition;
        return hasSatisfiedOrderCondition(
          addition?.orderConditions,
          checkout?.orderType,
          getItemAdditionsTotalSum(updatedCart, addition, checkout?.orderType),
          getItemAdditionQtySum(updatedCart, addition, checkout?.orderType),
        );
      }))
  ) {
    dispatch(setCart(updatedCart));
  } else {
    notification('warning', 'addToCartOrderConditionsError');
    console.error('Could not find OrderItem with hash ' + targetHash);
  }
};

export const addCartItem = (cart, itemData, additions = [], qty, orderType) => (
  dispatch,
) => {
  const targetHash = getOrderItemHash({ itemData, additions });
  const existingOrderItem = cart?.find(({ hash }) => targetHash === hash);
  const newOrderItem = {
    hash: targetHash,
    qty: qty,
    itemData: itemData,
    additions: additions,
    course: itemData.defaultCourse,
    type: 'OrderItem',
  };

  // check if price is less than 0 - that's not allowed
  const price = getOrderItemTotal(orderType, newOrderItem);
  if (price < 0) {
    notification('warning', 'priceBelowZeroError');
    return;
  }

  const updatedCart = (existingOrderItem
    ? [
        ...cart.filter(({ hash }) => targetHash !== hash), // all other items
        { ...existingOrderItem, qty: existingOrderItem.qty + qty }, // increase qty
      ]
    : [
        ...(cart || []), // current cart (if any)
        newOrderItem,
      ]
  ).sort((a, b) => a.hash.localeCompare(b.hash)); // sort by hash

  // dispatch the updated cart if OrderConditions are satisfied. Otherwise, show an Error Notification.
  if (
    hasSatisfiedOrderCondition(
      itemData.orderConditions,
      orderType,
      getItemDataTotalSum(updatedCart, itemData, orderType),
      getItemDataQtySum(updatedCart, itemData),
    ) &&
    additions.every((orderItemAddition) => {
      const addition = orderItemAddition.additionGroupEntry?.addition;
      return hasSatisfiedOrderCondition(
        addition?.orderConditions,
        orderType,
        getItemAdditionsTotalSum(updatedCart, addition, orderType),
        getItemAdditionQtySum(updatedCart, addition, orderType),
      );
    })
  ) {
    dispatch(setCart(updatedCart));
  } else {
    notification('warning', 'addToCartOrderConditionsError');
  }
};

export const removeCartItem = (cart, targetHash) => (dispatch) => {
  const updatedCart = cart
    ?.filter(({ hash }) => targetHash !== hash) // all other items
    .sort((a, b) => a.hash.localeCompare(b.hash)); // sort by hash

  dispatch(setCart(updatedCart));
};

export const fetchOrderStats = () => (dispatch) => {
  const now = new Date();
  const firstDateOfPreviousMonth = getFirstDateOfMonth(now, -1);
  const firstDateOfCurrentMonth = getFirstDateOfMonth(now);
  const lastDateOfPreviousMonth = getLastDateOfMonth(now, -1);
  const lastDateOfCurrentMonth = getLastDateOfMonth(now);

  const queryParamsThisMonth = {
    filter: [
      ['createdAt', '$gte', firstDateOfCurrentMonth.toISOString()],
      ['createdAt', '$lte', lastDateOfCurrentMonth.toISOString()],
    ],
  };
  const queryParamsLastMonth = {
    filter: [
      ['createdAt', '$gte', firstDateOfPreviousMonth.toISOString()],
      ['createdAt', '$lte', lastDateOfPreviousMonth.toISOString()],
    ],
  };

  Promise.all([
    getOrderStats(queryParamsThisMonth),
    getOrderStats(queryParamsLastMonth),
  ]).then(([thisMonthStats, lastMonthStats]) => {
    dispatch({
      type: 'SET_ORDER_STATS',
      payload: {
        thisMonth: thisMonthStats,
        lastMonth: lastMonthStats,
      },
    });
  });
};

/* export const fetchTables = () => (dispatch) => {
  const queryParams = {
    limit: 15,
  };
  getStoreTables(queryParams).then((tables) =>
    dispatch({
      type: 'SET_TABLES',
      payload: tables,
    }),
  );
}; */

/* export const fetchTables = (
  recentTableCreatedAt,
  oldestTableCreatedAt,
  onSuccessCallback = () => undefined,
) => (dispatch) => {
  const limit = 25;

  const fetchData = (offset = 0) => {
    const queryParams = {
      filter: [
        ...(recentTableCreatedAt
          ? [
              ['createdAt', '$gt', recentTableCreatedAt],
              ['createdAt', '$ne', recentTableCreatedAt],
            ]
          : []),
        ...(oldestTableCreatedAt
          ? [['createdAt', '$lt', oldestTableCreatedAt]]
          : []),
      ],
      sort: [{ field: 'createdAt', order: 'DESC' }],
      limit: limit,
      offset: offset,
    };

    getStoreTables(queryParams).then((tables) => {
      if (tables.length > 0) {
        dispatch({
          type: 'SET_TABLES',
          payload: tables,
        });
        onSuccessCallback(tables);
        fetchData(25 + limit);
      }
    });
  };

  fetchData();
}; */

export const fetchTables = (
  recentTableCreatedAt,
  oldestTableCreatedAt,
  onSuccessCallback = () => undefined,
) => (dispatch) => {
  const queryParams = {
    filter: [
      ...(recentTableCreatedAt
        ? [
            ['createdAt', '$gt', recentTableCreatedAt],
            ['createdAt', '$ne', recentTableCreatedAt],
          ]
        : []),
      ...(oldestTableCreatedAt
        ? [['createdAt', '$lt', oldestTableCreatedAt]]
        : []),
    ],
    sort: [{ field: 'createdAt', order: 'DESC' }],
    limit: 9999,
  };
  getStoreTables(queryParams).then((tables) => {
    dispatch({
      type: oldestTableCreatedAt ? 'ADD_TABLES' : 'SET_TABLES',
      payload: tables,
    });
    onSuccessCallback(tables);
  });
};

export const fetchDiscountCodes = (
  recentDiscountCodesCreatedAt,
  oldestDiscountCodesCreatedAt,
  onSuccessCallback = () => undefined,
) => (dispatch) => {
  const queryParams = {
    filter: [
      ...(recentDiscountCodesCreatedAt
        ? [
            ['createdAt', '$gt', recentDiscountCodesCreatedAt],
            ['createdAt', '$ne', recentDiscountCodesCreatedAt],
          ]
        : []),
      ...(oldestDiscountCodesCreatedAt
        ? [['createdAt', '$lt', oldestDiscountCodesCreatedAt]]
        : []),
    ],
    sort: [{ field: 'createdAt', order: 'DESC' }],
    limit: 50,
  };
  getStoreDiscountCodes(queryParams).then((discountCodes) => {
    dispatch({
      type: oldestDiscountCodesCreatedAt
        ? 'ADD_DISCOUNT_CODES'
        : 'SET_DISCOUNT_CODE',
      payload: discountCodes,
    });
    onSuccessCallback(discountCodes);
  });
};

export const setSpecificTable = (table) => (dispatch) => {
  dispatch({
    type: 'SET_SPECIFIC_TABLE',
    payload: table,
  });
};

export const setSpecificDiscountCode = (discount) => (dispatch) => {
  dispatch({
    type: 'SET_SPECIFIC_DISCOUNT_CODE',
    payload: discount,
  });
};

export const fetchPrinters = () => (dispatch) => {
  getStorePrinters({ join: ['categories'] }).then((printer) =>
    dispatch({
      type: 'SET_PRINTERS',
      payload: printer,
    }),
  );
};

export const fetchConnectors = () => (dispatch) => {
  getConnectors().then(({ data }) =>
    dispatch({
      type: 'SET_CONNECTORS',
      payload: data,
    }),
  );
};

export const fetchPickupStations = () => (dispatch) => {
  getStorePickupStations().then((pickupStations) =>
    dispatch({
      type: 'SET_PICKUP_STATIONS',
      payload: pickupStations,
    }),
  );
};

export const setCheckout = (checkout) => (dispatch) => {
  dispatch({
    type: 'SET_CHECKOUT',
    payload: checkout,
  });
};

export const setCheckoutBackup = (checkoutBackup) => (dispatch) => {
  dispatch({
    type: 'SET_CHECKOUT_BACKUP',
    payload: checkoutBackup,
  });
};

export const setOrderHash = (hash) => (dispatch) => {
  dispatch({
    type: 'SET_ORDER_HASH',
    payload: hash,
  });
};

export const setOrder = (order) => (dispatch) => {
  dispatch({
    type: 'SET_ORDER',
    payload: order,
  });
};

export const setTable = (table) => (dispatch) => {
  dispatch({
    type: 'SET_TABLE',
    payload: table,
  });
};

export const setKiosk = (kiosk) => (dispatch) => {
  dispatch({
    type: 'SET_KIOSK',
    payload: kiosk,
  });
};

export const fetchStoreOrders = (
  recentOrderCreatedAt,
  oldestOrderCreatedAt,
  onSuccessCallback = () => undefined,
) => (dispatch) => {
  const queryParams = {
    filter: [
      ['status', '$ne', ORDER_STATUS_PAID],
      ['status', '$ne', ORDER_STATUS_PENDING],
      ...(recentOrderCreatedAt
        ? [
            ['createdAt', '$gt', recentOrderCreatedAt],
            ['createdAt', '$ne', recentOrderCreatedAt],
          ]
        : []),
      ...(oldestOrderCreatedAt
        ? [['createdAt', '$lt', oldestOrderCreatedAt]]
        : []),
    ],
    join: [
      'deliveryAddress',
      'merchantProfile',
      'merchantProfile.store',
      'billingAddress',
      'payment',
      'items',
      'items.itemData',
      'items.additions',
      'items.additions.itemAddition',
      'table',
    ],
    sort: [{ field: 'createdAt', order: 'DESC' }],
    limit: 15,
  };
  getOrders(null, queryParams, true).then((orders) => {
    dispatch({
      type:
        recentOrderCreatedAt || oldestOrderCreatedAt
          ? 'SET_STORE_SPECIFIC_ORDERS'
          : 'SET_STORE_ORDERS',
      payload: orders,
    });
    onSuccessCallback(orders);
  });
};

export const fetchServiceTimes = (storeId) => (dispatch) => {
  getServiceTimes(storeId).then((serviceTimes) =>
    dispatch({
      type: 'SET_SERVICE_TIMES',
      payload: serviceTimes,
    }),
  );
};

export const fetchCurrentServiceTime = (storeId) => (dispatch) => {
  getCurrentServiceTime(storeId)
    .then((serviceTime) =>
      dispatch({
        type: 'SET_CURRENT_SERVICE_TIME',
        payload: serviceTime,
      }),
    )
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: 'SET_CURRENT_SERVICE_TIME',
          payload: null,
        });
      }
    });
};

export const fetchNextServiceTime = (storeId) => (dispatch) => {
  getNextStartServiceTime(storeId).then((serviceTime) =>
    dispatch({
      type: 'SET_NEXT_SERVICE_TIME',
      payload: serviceTime,
    }),
  );
};

export const setTableCart = (cart) => (dispatch) => {
  dispatch({
    type: 'SET_TABLE_CART',
    payload: cart,
  });
};

export const setSharedCarts = (sharedCarts) => (dispatch) => {
  dispatch({
    type: 'SET_TABLE_CARTS',
    payload: sharedCarts,
  });
};

export const addSearchStringsToMenuEntriesData = (menuEntries, locale) => (
  dispatch,
) => {
  const preperedMenuEntries = extractData(menuEntries)?.map((data) => ({
    ...data,
    availableAt:
      menuEntries?.find((menuEntry) =>
        menuEntry?.itemEntry?.data?.some((item) => item?.id === data?.id),
      )?.itemEntry?.availableAt || [],
    searchString: getLocaleHierarchy(locale)
      .map(
        (locale) =>
          `${data['name' + locale]}¦${
            data.type === 'FoodItemData' ? data['description' + locale] : ''
          }`,
      )
      .join('¦'),
  }));

  dispatch({
    type: 'SET_MENU_ENTRIES_DATA',
    payload: preperedMenuEntries,
  });
};

export const extractData = (menuEntries) =>
  menuEntries
    ?.map((menuEntry) =>
      menuEntry.type === 'CategoryMenuEntry'
        ? menuEntry.categoryEntry
        : menuEntry.type === 'InfoMenuEntry'
        ? menuEntry
        : menuEntry.itemEntry.data,
    )
    .flat();

export const fetchPosIntegrations = () => (dispatch) => {
  getPosIntegrations().then(({ data }) => {
    dispatch({
      type: 'SET_POS_INTEGRATIONS',
      payload: data,
    });
  });
};
export const setStoreSpecificOrder = (order) => (dispatch) => {
  dispatch({
    type: 'SET_STORE_SPECIFIC_ORDER',
    payload: order,
  });
};

export const setAvailableOrderTypes = (orderTypes) => (dispatch) => {
  dispatch({
    type: 'SET_AVAILABLE_ORDER_TYPES',
    payload: orderTypes,
  });
};

export const fetchPosIntegrationItemDataMappings = (posIntegrationId) => (
  dispatch,
) => {
  getPosIntegrationItemDataMappings(posIntegrationId).then((itemData) =>
    dispatch({
      type: 'SET_POS_INTEGRATION_ITEM_DATA_MAPPINGS',
      payload: itemData,
    }),
  );
};

export const fetchPosIntegrationItemAdditionMappings = (posIntegrationId) => (
  dispatch,
) => {
  getPosIntegrationItemAdditionMappings(posIntegrationId).then((additions) =>
    dispatch({
      type: 'SET_POS_INTEGRATION_ITEM_ADDITION_MAPPINGS',
      payload: additions,
    }),
  );
};

export const fetchPosIntegrationTableMappings = (posIntegrationId) => (
  dispatch,
) => {
  getPosIntegrationTableMappings(posIntegrationId).then((tables) =>
    dispatch({
      type: 'SET_POS_INTEGRATION_TABLE_MAPPINGS',
      payload: tables,
    }),
  );
};

export const fetchStores = (
  recentStoreCreatedAt,
  oldestStoreCreatedAt,
  onSuccessCallback = () => undefined,
  showDeletedStores
) => (dispatch) => {
  const queryParams = {
    filter: [
      ...(recentStoreCreatedAt
        ? [
            ['createdAt', '$gt', recentStoreCreatedAt],
            ['createdAt', '$ne', recentStoreCreatedAt],
          ]
        : []),
      ...(oldestStoreCreatedAt
        ? [['createdAt', '$lt', oldestStoreCreatedAt]]
        : []),
    ],
    limit: 25,
  };

  // Manually serialize the sort parameter
  const sortParams = 'createdAt,DESC';

  getStores({ ...queryParams, sort: sortParams }, showDeletedStores).then((res) => {
    dispatch({
      type:
        recentStoreCreatedAt || oldestStoreCreatedAt
          ? 'SET_SPECIFIC_STORES'
          : 'SET_ALL_STORES',
      payload: res?.data,
    });
    dispatch({
      type: 'SET_NUMBER_OF_STORES',
      payload: res?.total,
    });
    onSuccessCallback(res?.data);
  });
};


export const fetchSpecificStores = (searchString, setIsSearching) => (
  dispatch,
) => {
  const queryParams = {
    filter: [['name', '$contL', searchString]],
    sort: [{ field: 'createdAt', order: 'DESC' }],
  };
  getStores(queryParams).then((stores) => {
    setIsSearching(false);
    dispatch({
      type: 'SET_SPECIFIC_STORES',
      payload: stores,
    });
  });
};

export const fetchAllStoresStats = () => (dispatch) => {
  const now = new Date();
  const firstDateOfPreviousMonth = getFirstDateOfMonth(now, -1);
  const firstDateOfCurrentMonth = getFirstDateOfMonth(now);
  const lastDateOfPreviousMonth = getLastDateOfMonth(now, -1);
  const lastDateOfCurrentMonth = getLastDateOfMonth(now);

  const queryParamsThisMonth = {
    filter: [
      ['createdAt', '$gte', firstDateOfCurrentMonth.toISOString()],
      ['createdAt', '$lte', lastDateOfCurrentMonth.toISOString()],
    ],
  };
  const queryParamsLastMonth = {
    filter: [
      ['createdAt', '$gte', firstDateOfPreviousMonth.toISOString()],
      ['createdAt', '$lte', lastDateOfPreviousMonth.toISOString()],
    ],
  };

  Promise.all([
    getAllStoresStats(queryParamsThisMonth),
    getAllStoresStats(queryParamsLastMonth),
  ]).then(([thisMonthStats, lastMonthStats]) => {
    dispatch({
      type: 'SET_ALL_STORES_STATS',
      payload: {
        thisMonth: thisMonthStats,
        lastMonth: lastMonthStats,
      },
    });
  });
};

export const fetchAdditionGroups = (onSuccessCallback = () => undefined) => (
  dispatch,
) => {
  getAdditionGroups()
    .then((additionGroups) =>
      dispatch({
        type: 'SET_ADDITION_GROUPS',
        payload: additionGroups.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        ),
      }),
    )
    .then(() => onSuccessCallback());
};

export const fetchKiosks = () => (dispatch) => {
  getKiosks().then((kiosks) =>
    dispatch({
      type: 'SET_KIOSKS',
      payload: kiosks,
    }),
  );
};

export const fetchPaymentTerminals = () => (dispatch) => {
  getPaymentTerminals().then((terminals) =>
    dispatch({
      type: 'SET_PAYMENT_TERMINALS',
      payload: terminals,
    }),
  );
};

export const fetchAreas = () => (dispatch) => {
  getAreas().then((areas) =>
    dispatch({
      type: 'SET_AREAS',
      payload: areas,
    }),
  );
};

import React from 'react';
import {
  getImageUrl,
  getImageVariant,
} from '../../../../utility/util/util.service';
import PropTypes from 'prop-types';
import SkeletonLoader from '../skeleton-loader/skeleton-loader.component';
import ProgressiveImage from 'react-progressive-graceful-image';

const LazyImage = ({
  image, // Image object
  variant, // Cloudflare Images variant code
  src, // fallback if no image was given
  className,
  placeholderClassNames,
  placeholderContainerClassNames,
  stylesForLoader,
  ...props
}) => {
  const targetVariant = getImageVariant(variant);
  const imageUrl = getImageUrl(image, targetVariant);

  return (
    <ProgressiveImage
      src={imageUrl || src}
      retry={{ count: 10, delay: 3, accumulate: 'add' }}
      placeholder=""
      /*rootMargin="-10% -10% -10%"*/
      /*threshold={[1]}*/
      {...props}
    >
      {(src, loading) =>
        loading ? (
          <div className={placeholderContainerClassNames}>
            <SkeletonLoader className={placeholderClassNames} />
          </div>
        ) : (
          <img className={className} src={src} alt={image?.fileName || ''} />
        )
      }
    </ProgressiveImage>
  );
};

LazyImage.propTypes = {
  image: PropTypes.any,
  variant: PropTypes.string,
  src: PropTypes.any,
  className: PropTypes.string,
  placeholderClassNames: PropTypes.string,
  placeholderContainerClassNames: PropTypes.string,
};

export default LazyImage;

import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Layout } from './context/layout.context';
import * as serviceWorker from './serviceWorker';
import { storeConfig } from './redux/store.config';
import { IntlProvider } from './context/intl.context';
import { HelmetProvider } from 'react-helmet-async';
import './index.scss';
import './@fake-db';
import AppLoader from './components/@servierbar/lazyload/app-loader/app-loader.component';
import { Auth0Provider } from '@auth0/auth0-react';
import { config, isCloudflarePages } from './configs/app.config';
import { SocketProvider } from './context/socket.context';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { Route } from 'react-router-dom';
import { withRetry } from './utility/util/util.service';

export const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();

if (isCloudflarePages) {
  Sentry.init({
    environment: config.env,
    dsn: config.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    //tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const LazyApp = lazy(() => withRetry(() => import('./App')));

// render the dom
ReactDOM.render(
  <Auth0Provider
    domain={config.auth0.domain}
    clientId={config.auth0.clientId}
    redirectUri={window.location.origin + '/redirect'} // needed because root will lead to 404
    audience={config.auth0.audience}
    cacheLocation="localstorage" // TODO: use refresh tokens. Without this cache, it logs out after refresh
  >
    <Provider store={storeConfig}>
      <Suspense fallback={<AppLoader />}>
        <Layout>
          <IntlProvider>
            <SocketProvider>
              <HelmetProvider>
                <LazyApp />
              </HelmetProvider>
            </SocketProvider>
          </IntlProvider>
        </Layout>
      </Suspense>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

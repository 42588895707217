import React, { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { config } from '../configs/app.config';
import { connect } from 'react-redux';
import {
  setOrder,
  setSharedCarts,
  setStoreSpecificOrder,
  setTableCart,
} from '../redux/store/store.actions';
import { setSpecificOrder } from '../redux/me/me.actions';
import {
  handleExpectedTimeUpdateNotification,
  handleOrderUpdateNotification,
} from '../utility/notification/notification.service';
import retry from 'async-retry';
import {
  setOrderInfoModalData,
  setIsOrderInfoModalOpen,
} from '../redux/modal/modal.actions';
import {
  CHECKOUT_METHOD_PAY_AND_GO,
  ORDER_STATUS_PREPARING,
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_PICKUP,
} from '../utility/constants';
import { convertCartToOrderItems } from '../utility/util/util.service';
import { debounce } from 'debounce';

const Context = React.createContext({});

const SocketProviderWrapper = ({
  me,
  children,
  table,
  cart,
  checkout,
  orders,
  order,
  storeOrders,

  setTableCart,
  setSpecificOrder,
  setStoreSpecificOrder,
  setSharedCarts,
  setOrderInfoModalData,
  setIsOrderInfoModalOpen,
  setOrder,
}) => {
  const socket = useRef(null);
  const [token, setToken] = useState(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [
    hasCustomerProfileSubscribed,
    setHasCustomerProfileSubscribed,
  ] = useState(false);
  const [hasJoinedTable, setHasJoinedTable] = useState(false);
  const cartQty = useRef(0);
  const ordersRef = useRef(orders); // hold the latest orders state
  const tableCartUpdateDebouncer = useRef(null);

  // Update the ordersRef whenever orders changes
  useEffect(() => {
    ordersRef.current = orders || [];
  }, [orders]);

  useEffect(() => {
    if (token) {
      socket.current = io(config.wsBaseUrl, {
        transports: ['websocket'],
        auth: { token },
        reconnection: true,
        autoConnect: true,
      });

      socket.current.on('connect', () => {
        console.info('Connected to Socket!');
        setIsSocketConnected(true);

        retry(() => socket.current.timeout(2000).emitWithAck('subscribe')).then(
          (msg) => {
            console.info(`Subscribed successfully! (${msg})`);
            setHasCustomerProfileSubscribed(true);
          },
        );
      });

      socket.current.on('connect_error', () => {
        console.info('Cannot connect to Socket, retrying...');
      });

      socket.current.on('error', (err) => {
        console.info('Socket Error:', err.message);
      });

      socket.current.on('disconnect', (reason) => {
        console.info(`Disconnected from Socket (${reason})`);
        setIsSocketConnected(false);
        setHasCustomerProfileSubscribed(false);
        setHasJoinedTable(false);
      });

      socket.current.on('order', (data, ack) => {
        ack('OK'); // acknowledge event first
        const updatedOrder = data?.order;

        console.info(
          `Received update for Order (id=${updatedOrder.id})`,
          updatedOrder,
        );

        if (data?.to === 'CustomerProfile') {
          handleOrderUpdateNotification(updatedOrder, ordersRef.current);
          handleExpectedTimeUpdateNotification(updatedOrder, ordersRef.current);
          setSpecificOrder(updatedOrder); // updates the array of all Orders (User)

          // update the order while in checkout, but only if it is the same Order!
          if (order?.id === updatedOrder?.id) setOrder(updatedOrder);
        }

        if (data?.to === 'MerchantProfile') {
          if (
            updatedOrder?.status === ORDER_STATUS_PREPARING &&
            ((updatedOrder.type === ORDER_TYPE_PICKUP &&
              !updatedOrder.pickupConfirmedAt) ||
              (updatedOrder.type === ORDER_TYPE_DELIVERY &&
                !updatedOrder?.deliveryConfirmedAt))
          ) {
            setOrderInfoModalData({ order: updatedOrder, isAdminMode: true });
            setIsOrderInfoModalOpen(true);
          }

          setStoreSpecificOrder(updatedOrder); // updates the array of all Orders (Store)
        }
      });

      socket.current.on('scanner/action', (data, ack) => {
        ack('OK'); // acknowledge event first
        const { payload, action } = data;

        //in this case payload is order
        if (['order-info', 'order-completed'].includes(action)) {
          const order = payload;

          console.info(
            `Received Order from Scan action "${action}" (id=${order.id})`,
            order,
          );

          setOrderInfoModalData({ order, isAdminMode: true });
          setIsOrderInfoModalOpen(true);
        }
      });

      socket.current.on('table/shared-carts', (carts, ack) => {
        ack('OK'); // acknowledge event first
        console.info('Received SharedCarts update', carts);
        setSharedCarts(carts);
      });

      socket.current.on('table/cart', (tableCart, ack) => {
        ack('OK'); // acknowledge event first
        console.info('Received TableCart update', tableCart);
        if (tableCartUpdateDebouncer.current) {
          tableCartUpdateDebouncer.current.clear();
        }
        tableCartUpdateDebouncer.current = debounce(() => {
          setTableCart(tableCart);
        }, 300);
        tableCartUpdateDebouncer.current();
      });
    }

    return () => {
      if (socket.current?.connected) {
        socket.current.disconnect();
      }
    };
  }, [token]);

  // join-table process
  useEffect(() => {
    if (
      isSocketConnected &&
      hasCustomerProfileSubscribed &&
      !hasJoinedTable &&
      table
    ) {
      const joinTablePayload = { tableId: table.id };
      retry(() =>
        socket.current
          .timeout(2000)
          .emitWithAck('table/join', joinTablePayload),
      ).then((msg) => {
        console.info(`Joined Table "${table.name}" (${msg})`, joinTablePayload);
        setHasJoinedTable(true);
      });
    }
  }, [isSocketConnected, hasCustomerProfileSubscribed, hasJoinedTable, table]);

  useEffect(() => {
    if (
      isSocketConnected &&
      hasCustomerProfileSubscribed &&
      hasJoinedTable &&
      !table
    ) {
      retry(() => socket.current.timeout(2000).emitWithAck('table/leave')).then(
        (msg) => {
          console.log(`Left Table (${msg})`);
          setHasJoinedTable(false);
        },
      );
    }
  }, [isSocketConnected, hasCustomerProfileSubscribed, hasJoinedTable, table]);

  // share cart everytime it changes
  useEffect(() => {
    if (table && checkout.checkoutMethod !== CHECKOUT_METHOD_PAY_AND_GO) {
      // there is a new item or item deleted from cart
      if (cart?.length !== cartQty.current) {
        cartQty.current = cart?.length;

        // only share cart when not empty
        if (cart?.length > 0) {
          if (hasJoinedTable && hasCustomerProfileSubscribed) {
            const ownCart = { items: convertCartToOrderItems(cart) };

            retry(() =>
              socket.current
                .timeout(2000)
                .emitWithAck('table/shared-carts', ownCart),
            ).then((msg) => {
              console.info(`Shared own Cart (${msg})`, ownCart);
            });
          } else {
            console.error(
              'Cannot share your Cart as you have not joined a Table!',
            );
          }
        }
      }
    }
  }, [table, hasJoinedTable, hasCustomerProfileSubscribed, cart, me]);

  const handleEmitSelectOrderItem = (selectionId, mode) => {
    if (hasJoinedTable && hasCustomerProfileSubscribed) {
      retry(() =>
        socket.current
          .timeout(2000)
          .emitWithAck('table/cart', { selectionId, mode }),
      ).then((msg) => {
        console.info(`Shared own Cart (${msg})`);
      });
    } else {
      console.error('Cannot share your Cart as you have not joined a Table!');
    }
  };

  return (
    <Context.Provider
      value={{
        socket: socket.current,
        setToken,
        handleEmitSelectOrderItem,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const mapStateToProps = (state) => ({
  me: state.me.me,
  table: state.store.table,
  cart: state.store.cart,
  checkout: state.store.checkout,
  order: state.store.order,
  sharedCarts: state.store.sharedCarts,
  orders: state.me.orders,
  storeOrders: state.store.storeOrders,
});

export const SocketContext = Context;

export const SocketProvider = connect(mapStateToProps, {
  setTableCart,
  setSpecificOrder,
  setStoreSpecificOrder,
  setSharedCarts,
  setOrderInfoModalData,
  setIsOrderInfoModalOpen,
  setOrder,
})(SocketProviderWrapper);

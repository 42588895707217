import themeConfig from '../../configs/theme.config';

const initialState = {
  theme: themeConfig.theme,
  // prevents that the sidebar is initially open on mobile
  isSidebarOpen: window.innerWidth < 1200 ? false : themeConfig.isSidebarOpen,
  navbarColor: themeConfig.navbarColor,
  navbarType: themeConfig.navbarType,
  footerType: themeConfig.footerType,
  menuTheme: themeConfig.menuTheme,
  hideScrollToTop: themeConfig.hideScrollToTop,
  showAppOverlay: false,
};

export default function customizerReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_THEME':
      return { ...state, theme: action.payload };
    case 'SET_IS_SIDEBAR_OPEN':
      return { ...state, isSidebarOpen: action.payload };
    case 'SET_NAVBAR_COLOR':
      return { ...state, navbarColor: action.payload };
    case 'SET_NAVBAR_TYPE':
      return { ...state, navbarType: action.payload };
    case 'SET_FOOTER_TYPE':
      return { ...state, footerType: action.payload };
    case 'SET_MENU_THEME':
      return { ...state, menuTheme: action.payload };
    case 'SET_HIDE_SCROLL_TO_TOP':
      return { ...state, hideScrollToTop: action.payload };
    case 'SET_SHOW_APP_OVERLAY':
      return { ...state, showAppOverlay: action.payload };
    default:
      return state;
  }
}

// Routes
export const ROUTE_ERROR_404 = '/404';
export const ROUTE_ERROR_500 = '/500';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_SIGNUP_STORE = ROUTE_SIGNUP + '/store';
export const ROUTE_STORE = '/store';
export const ROUTE_TABLE = '/table';
export const ROUTE_KIOSK = '/kiosk';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_DASHBOARD_SETTINGS = ROUTE_DASHBOARD + '/settings';
export const ROUTE_DASHBOARD_ORDERS = ROUTE_DASHBOARD + '/orders';

// Order Types
export const ORDER_TYPE_DELIVERY = 'DeliveryOrder';
export const ORDER_TYPE_PICKUP = 'PickupOrder';
export const ORDER_TYPE_INHOUSE = 'InhouseOrder';
export const ORDER_TYPE_CATERING = 'CateringOrder';
export const ORDER_TYPE_SELF_SERVICE = 'SelfServiceOrder';

// Checkout Methods
export const CHECKOUT_METHOD_ORDER_AND_PAY = 'order_and_pay';
export const CHECKOUT_METHOD_ORDER_ONLY = 'order_only';
export const CHECKOUT_METHOD_PAY_AND_GO = 'pay_and_go';

// Addresses
export const DELIVERY_ADDRESS = 'DeliveryAddress';
export const BILLING_ADDRESS = 'BillingAddress';

// Order states
export const ORDER_STATUS_PENDING = 'pending';
export const ORDER_STATUS_PAID = 'paid';
export const ORDER_STATUS_PROCESSING = 'processing';
export const ORDER_STATUS_PREPARING = 'preparing';
export const ORDER_STATUS_PREPARED = 'prepared';
export const ORDER_STATUS_DELIVERING = 'delivering';
export const ORDER_STATUS_COMPLETED = 'completed';
export const ORDER_STATUS_CANCELLED = 'cancelled';
export const ORDER_STATUS_REFUNDED = 'refunded';
export const ORDER_STATUS_DISPUTED = 'disputed';

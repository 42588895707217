import React from 'react';
import themeConfig from '../configs/theme.config';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const FullPageLayout = ({ boxed, flexBox, children, ...rest }) => {
  return (
    <div
      className={classnames('full-layout wrapper blank-page dark-layout', {
        'layout-dark': themeConfig.layoutDark,
      })}
    >
      <div className="app-content">
        <div className={classnames('content-wrapper', { boxed: boxed })}>
          <div className="content-body">
            {flexBox ? (
              <div className="flexbox-container">
                <main className="main w-100">{children}</main>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

FullPageLayout.propTypes = {
  children: PropTypes.any,
};

export default FullPageLayout;

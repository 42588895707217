import React, { useEffect, useState } from 'react';
import { IntlProvider as IntlProviderBase } from 'react-intl';
import messagesEN from '../assets/data/locales/en.json';
import messagesDE from '../assets/data/locales/de.json';
import messagesFR from '../assets/data/locales/fr.json';
import messagesIT from '../assets/data/locales/it.json';
import messagesES from '../assets/data/locales/es.json';
import messagesHU from '../assets/data/locales/hu.json';
import PropTypes from 'prop-types';
import { getBrowserLanguage } from '../utility/util/util.service';
import { connect } from 'react-redux';
import { updateCustomerProfile } from '../utility/sb-api/sb-api.service';
import moment from 'moment';
import flatpickr from 'flatpickr';
import l10n from 'flatpickr/dist/l10n';
import { Settings } from 'luxon';

const availableMessages = {
  en: messagesEN,
  de: messagesDE,
  fr: messagesFR,
  it: messagesIT,
  es: messagesES,
  hu: messagesHU,
};

const Context = React.createContext({});

// locale is usually something like de-DE. Since we are getting just the first 2 chars, we call it language here.
const IntlProviderWrapper = ({ me, children }) => {
  const [locale, setLocale] = useState(getBrowserLanguage()); // set browser language initially
  const customerProfile = me?.requester?.customerProfile;

  const saveUserLanguage = (language) =>
    updateCustomerProfile(customerProfile?.id, {
      language: language,
    });

  // update language when me gets available
  useEffect(() => {
    if (customerProfile?.language) {
      setLocale(customerProfile.language);
    }
  }, [me]);

  // update when locale changes
  useEffect(() => {
    if (locale) {
      // set plugin global locales
      moment.locale(String(locale));
      flatpickr.localize(l10n[locale]);
      Settings.defaultLocale = locale;

      if (customerProfile?.language) {
        saveUserLanguage(locale).then(() => {
          console.log('Persist (initial) User language to ' + locale);
        });
      }
    }
  }, [locale]);

  return (
    <Context.Provider
      value={{
        locale: locale,
        switchLocale: (locale) => {
          setLocale(locale);

          // this is only possible if there is a User/Guest
          if (me) {
            saveUserLanguage(locale).then(() => {
              console.log('Persist User language to ' + locale);
            });
          }
        },
      }}
    >
      <IntlProviderBase
        locale={locale}
        messages={availableMessages[locale]}
        formats={{
          number: {
            priceCurrency: {
              style: 'currency',
              currency: 'EUR',
            },
            priceDecimal: {
              style: 'decimal',
              minimumIntegerDigits: 1,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
            taxPercentage: {
              style: 'percent',
              minimumIntegerDigits: 1,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            },
            hours: {
              style: 'unit',
              unit: 'hour',
              minimumIntegerDigits: 1,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            },
            minutes: {
              style: 'unit',
              unit: 'minute',
              minimumIntegerDigits: 1,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            },
          },
          date: {
            fullRegular: {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            },
            full2Digit: {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            },
            dateRegular: {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            },
            date2Digit: {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            },
            timeRegular: {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            },
          },
          relative: {
            long: {
              style: 'long',
              numeric: 'auto',
            },
            since: {
              style: 'short',
              numeric: 'always',
            },
          },
        }}
      >
        {children}
      </IntlProviderBase>
    </Context.Provider>
  );
};

IntlProviderWrapper.propTypes = {
  children: PropTypes.any,
};

const mapStateToProps = (state) => ({
  me: state.me.me,
});

export const IntlContext = Context;
export const IntlProvider = connect(mapStateToProps)(IntlProviderWrapper);

import React, { useState } from 'react';
import classnames from 'classnames';
import { ContextLayout } from '../context/layout.context';
import { connect } from 'react-redux';
import SidebarHeader from './sidebar-header.component';
import Hammer from 'react-hammerjs';
import SideMenuContent from './sidemenu-content.component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { setIsSidebarOpen } from '../redux/customizer/customizer.actions';
import PropTypes from 'prop-types';

const Sidebar = ({ width, isSidebarOpen, menuTheme, setIsSidebarOpen }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <ContextLayout.Consumer>
      {(context) => {
        const dir = context.direction;

        return (
          <React.Fragment>
            <Hammer
              onSwipe={() => setIsSidebarOpen(true)}
              direction={dir === 'rtl' ? 'DIRECTION_LEFT' : 'DIRECTION_RIGHT'}
            >
              <div className="menu-swipe-area d-xl-none d-block vh-100"></div>
            </Hammer>

            <div
              className={classnames(
                `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${menuTheme}`,
                {
                  collapsed: width >= 1200 && !(isSidebarOpen || isHovering),
                  'hide-sidebar': width < 1200 && !isSidebarOpen,
                },
              )}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <SidebarHeader />

              <PerfectScrollbar
                className={classnames('main-menu-content overflow-hidden')}
                options={{ wheelPropagation: false }}
              >
                <Hammer
                  onSwipe={() => setIsSidebarOpen(false)}
                  direction={
                    dir === 'rtl' ? 'DIRECTION_RIGHT' : 'DIRECTION_LEFT'
                  }
                >
                  <ul className="navigation navigation-main">
                    <SideMenuContent />
                  </ul>
                </Hammer>
              </PerfectScrollbar>
            </div>
          </React.Fragment>
        );
      }}
    </ContextLayout.Consumer>
  );
};

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  menuTheme: PropTypes.string,
  store: PropTypes.object,
  setIsSidebarOpen: PropTypes.func,
  width: PropTypes.number,
};

const mapStateToProps = (state) => ({
  me: state.me.me,
  isSidebarOpen: state.customizer.isSidebarOpen,
  menuTheme: state.customizer.menuTheme,
});

export default connect(mapStateToProps, {
  setIsSidebarOpen,
})(Sidebar);

import React from 'react';
import { Spinner } from 'reactstrap';

const AppLoader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner color="primary" size="lg" />
    </div>
  );
};

export default AppLoader;

export const setTheme = (theme) => (dispatch) =>
  dispatch({ type: 'SET_THEME', payload: theme });

export const setIsSidebarOpen = (isOpen) => (dispatch) =>
  dispatch({ type: 'SET_IS_SIDEBAR_OPEN', payload: isOpen });

export const setNavbarColor = (color) => (dispatch) =>
  dispatch({ type: 'SET_NAVBAR_COLOR', payload: color });

export const setNavbarType = (type) => (dispatch) =>
  dispatch({ type: 'SET_NAVBAR_TYPE', payload: type });

export const setFooterType = (type) => (dispatch) =>
  dispatch({ type: 'SET_FOOTER_TYPE', payload: type });

export const setMenuTheme = (theme) => (dispatch) =>
  dispatch({ type: 'SET_MENU_THEME', payload: theme });

export const setHideScrollToTop = (isHidden) => (dispatch) =>
  dispatch({ type: 'HIDE_SCROLL_TO_TOP', payload: isHidden });

export const setShowAppOverlay = (isShown) => (dispatch) =>
  dispatch({ type: 'SET_SHOW_APP_OVERLAY', payload: isShown });

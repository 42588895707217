import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createDebounce from 'redux-debounced';
import thunk from 'redux-thunk';
import modals from './modal/modal.reducer';
import store from './store/store.reducer';
import me from './me/me.reducer';
import customizer from './customizer/customizer.reducer';

const combinedReducers = combineReducers({
  customizer,
  modals,
  store,
  me,
});
const middlewares = [thunk, createDebounce()];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const storeConfig = createStore(
  combinedReducers,
  {},
  composeEnhancers(applyMiddleware(...middlewares)),
);

export { storeConfig };
